import { combineReducers } from 'redux';

import auth from './auth';
import generic from './generic';
import { LOGOUT_SUCCESS } from '../actions/auth';

const appReducers = combineReducers({
  auth,
  generic,
});

const reducers = (state, action) => {
  let newState = { ...state };

  switch (action.type) {
    case LOGOUT_SUCCESS:
      localStorage.clear();
      newState = {
        ...newState,
        auth: {
          isLoggedIn: false,
          token: null,
        },
      };
      break;

    default:
      break;
  }

  return appReducers(newState, action);
};

export default reducers;
