import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import Creatable from 'react-select/creatable';
import classNames from 'classnames';

const CreatableSelect = ({
  label,
  required,
  name,
  rightContent,
  wrapperClassName,
  className,
  options,
  value,
  placeholder,
  formInput,
  handleChange,
  handleCreate,
  disabled,
  isLoading,
  isClearable,
}) => {
  return (
    <div className={classNames('select-wrapper', wrapperClassName)}>
      {label && (
        <label htmlFor={name}>
          {label} <span>{required ? '*' : ''}</span>
        </label>
      )}
      {rightContent && (
        <span className="input-right-content">{rightContent}</span>
      )}
      <Creatable
        isClearable={isClearable}
        isDisabled={isLoading || disabled}
        isLoading={isLoading}
        onChange={handleChange}
        onCreateOption={handleCreate}
        placeholder={placeholder}
        options={options}
        value={value}
        classNamePrefix={className || 'select-dropdown'}
      />
      {formInput && (
        <div className="error-msg">
          <ErrorMessage name={name} />
        </div>
      )}
    </div>
  );
};

CreatableSelect.propTypes = {
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rightContent: PropTypes.node,
  formInput: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isClearable: PropTypes.bool,
};

CreatableSelect.defaultProps = {
  label: '',
  required: false,
  wrapperClassName: '',
  className: '',
  rightContent: '',
  placeholder: 'Enter / Select',
  formInput: true,
  disabled: false,
  isLoading: false,
  isClearable: false,
};

export default CreatableSelect;
