import React from 'react';
import cx from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';

const TabHeaders = props => {
  const {
    headers,
    onTabSelect,
    activeTabIndex,
    showClose,
    pullLeft,
    showCloseForActive,
    onTabClose,
    headerContainerClassName,
    headerClassName,
    headerLinkClassName,
    tabIndexClassName,
    skipCloseForFirstTab,
  } = props;

  const _defaultHeaderRenderer = ({
    header,
    shouldShowClose,
    handleClose,
    index,
    skipCloseForFirstTab,
    tabLength,
  }) => {
    let showHeaderClose = false;
    if (shouldShowClose) {
      if (index === 0 && skipCloseForFirstTab && tabLength === 1) {
        showHeaderClose = false;
      } else {
        showHeaderClose = true;
      }
    }

    return (
      <>
        {header}
        {showHeaderClose && (
          <small className="ml-2">
            <i
              className="fas fa-times"
              onClick={handleClose}
              onKeyUp={handleClose}
              role="button"
              aria-label="close"
              tabIndex="-42"
            />
          </small>
        )}
      </>
    );
  };

  const renderHeader = ({
    header,
    shouldShowClose,
    handleClose,
    index,
    skipCloseForFirstTab,
    tabLength,
  }) => {
    if (props.renderHeader) {
      return props.renderHeader({ header, shouldShowClose, handleClose });
    }

    return _defaultHeaderRenderer({
      header,
      shouldShowClose,
      handleClose,
      index,
      skipCloseForFirstTab,
      tabLength,
    });
  };

  return (
    <Nav
      tabs
      className={cx(
        'tab-headers',
        { 'pull-left': pullLeft },
        headerContainerClassName
      )}
    >
      {headers.map((header, index) => {
        let shouldShowClose = false;
        if (showClose) {
          if (showCloseForActive) {
            shouldShowClose = index === activeTabIndex;
          } else {
            shouldShowClose = true;
          }
        }

        const handleClose = e => {
          if (onTabClose) {
            onTabClose(index, e);
          }
        };

        const navLinkClass = tabIndexClassName && tabIndexClassName[index];

        return (
          <NavItem className={cx(navLinkClass, 'tab-header', headerClassName)}>
            <NavLink
              className={cx(
                { active: index === activeTabIndex },
                headerLinkClassName
              )}
              disabled={tabIndexClassName && tabIndexClassName[index]}
              onClick={e => {
                onTabSelect(index, e);
              }}
            >
              {renderHeader({
                header,
                shouldShowClose,
                handleClose,
                index,
                skipCloseForFirstTab,
                tabLength: headers?.length,
              })}
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  );
};

TabHeaders.propTypes = {
  headers: PropTypes.array.isRequired,
  activeTabIndex: PropTypes.number,
  showClose: PropTypes.bool,
  pullLeft: PropTypes.bool,
  showCloseForActive: PropTypes.bool,
  renderHeader: PropTypes.func,

  // Events
  onTabSelect: PropTypes.func,
  onTabClose: PropTypes.func,

  // Styling
  headerContainerClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  headerLinkClassName: PropTypes.string,
};

export default TabHeaders;
