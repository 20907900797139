/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Select from '@khanacademy/react-multi-select';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ErrorMessage } from 'formik';

const MultiSelect = props => {
  const {
    name,
    label,
    value,
    onChange,
    required,
    showIcon,
    formInput,
    placeholder,
    wrapperClassName,
    disabled,
    ...rest
  } = props;

  return (
    <div
      className={cx(
        'multi-select-wrap',
        { 'no-icon': !showIcon },
        { 'dropdown-disabled': disabled },
        wrapperClassName
      )}
    >
      {label && (
        <label htmlFor={name}>
          {label} <span className="input-mandatory">{required ? '*' : ''}</span>
        </label>
      )}
      <Select
        overrideStrings={{
          selectSomeItems: 'Select',
          allItemsAreSelected: 'All',
          selectAll: 'All',
          // search: "Search",
          className: 'multi-select-dropdown',
        }}
        name={name}
        placeholder={placeholder}
        onSelectedChanged={onChange}
        selected={value}
        required={required}
        disabled={disabled}
        // options={options}
        // search={false}
        // disableSearch
        // disabled={this.props.disabled}
        // hasSelectAll={this.props.hasSelectAll}
        // id={this.props.id}
        {...rest}
      />
      {formInput && (
        <div className="error-msg">
          <ErrorMessage name={name} />
        </div>
      )}
    </div>
  );
};

MultiSelect.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  showIcon: PropTypes.bool,
  formInput: PropTypes.bool,
  placeholder: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

MultiSelect.defaultProps = {
  name: '',
  label: '',
  value: '',
  onChange: () => {},
  required: false,
  showIcon: false,
  formInput: false,
  placeholder: 'Select',
  wrapperClassName: '',
  disabled: false,
};

export default MultiSelect;
