/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ConfirmationModal extends Component {
  render() {
    const {
      msg,
      title,
      cancelNavigation,
      toggleAlertState,
      proceedNavigation,
    } = this.props;
    return (
      <Modal
        isOpen={toggleAlertState}
        toggle={cancelNavigation}
        className="alertMessage-wrapper"
        backdrop="static"
      >
        <form className="trust-form">
          <ModalHeader>
            <i
              className="modal-close fas fa-times"
              onClick={cancelNavigation}
            />
            <span className="modal-headername theme-error">
              {title || 'Message'}
              <i className="ml-2 fas fa-exclamation-circle theme-error" />
            </span>
          </ModalHeader>
          <ModalBody>{msg}</ModalBody>
          <ModalFooter>
            <button
              onClick={cancelNavigation}
              type="button"
              className="btn-theme theme-cancel"
            >
              Cancel
            </button>
            <button
              onClick={proceedNavigation}
              type="button"
              className="btn-theme btn-theme-success"
            >
              OK
            </button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

ConfirmationModal.propTypes = {
  msg: PropTypes.string,
  title: PropTypes.string,
  toggleAlertState: PropTypes.bool,
  cancelNavigation: PropTypes.func,
  proceedNavigation: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  msg: '',
  title: 'Message',
  toggleAlertState: false,
  cancelNavigation: () => {},
  proceedNavigation: () => {},
};

export default ConfirmationModal;
