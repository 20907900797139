import React from 'react';
import PropTypes from 'prop-types';

import BackButton from '../back-button/back-button';

const ErrorBlock = ({ errorMessage, hideBackBtn }) => {
  return (
    <div className="error-wrapper">
      <h4 className="error-msg">
        <i className="fa fa-exclamation-triangle" />
      </h4>
      <p className="error-msg-text">{errorMessage}</p>
      {!hideBackBtn && <BackButton />}
    </div>
  );
};

ErrorBlock.defaultProps = {
  errorMessage: 'Could not load data. Please check back later.',
  hideBackBtn: false,
};

ErrorBlock.propTypes = {
  errorMessage: PropTypes.string,
  hideBackBtn: PropTypes.bool,
};

export default ErrorBlock;
