import React from 'react';
import PropTypes from 'prop-types';

function QuantityButton({
  quantity,
  addQuantity,
  decreaseQuantity,
  decreaseDisable,
  increaseDisable,
}) {
  return (
    <div className="wrapper wrapper-incremental-button">
      <button
        type="button"
        className="minusButton"
        disabled={decreaseDisable}
        onClick={decreaseQuantity}
      >
        -
      </button>
      <span className="quantitySpan">{quantity}</span>
      <button
        type="button"
        className="plusButton"
        onClick={addQuantity}
        disabled={increaseDisable}
      >
        +
      </button>
    </div>
  );
}

QuantityButton.propTypes = {
  quantity: PropTypes.number.isRequired,
  addQuantity: PropTypes.func.isRequired,
  decreaseQuantity: PropTypes.func,
  decreaseDisable: PropTypes.bool,
  increaseDisable: PropTypes.bool,
};

QuantityButton.defaultProps = {
  decreaseQuantity: () => {},
  decreaseDisable: false,
  increaseDisable: false,
};

export default QuantityButton;
