import React from 'react';
import { Table as ReactTable } from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';

const buildTableHeader = tableHeaderTitles => {
  const tableRows = _.map(tableHeaderTitles, tableHeaderTitle => {
    return <th className="table-header-title">{tableHeaderTitle}</th>;
  });

  return (
    <thead>
      <tr>{tableRows}</tr>
    </thead>
  );
};

const Table = props => {
  const { headers, children, className } = props;

  return (
    // TODO We can change this in future.
    <ReactTable bordered className={className}>
      {buildTableHeader(headers)}
      {children}
    </ReactTable>
  );
};

Table.defaultProps = {
  headers: [],
  children: null,
  className: '',
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Table;
