/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const AlertMessage = ({
  toggleAlertModal,
  toggleAlertState,
  messageState,
  title,
  okButtonText,
  showCancelButton,
  successMsg,
  onConfirm,
  cancelMsg,
}) => {
  return (
    <Modal
      isOpen={toggleAlertState}
      toggle={toggleAlertModal}
      className="alertMessage-wrapper"
      backdrop="static"
    >
      <form className="trust-form">
        <ModalHeader>
          <i className="modal-close fas fa-times" onClick={toggleAlertModal} />
          <span
            className={`modal-headername${
              messageState ? ' theme-primary' : ' theme-error'
            }`}
          >
            {title || 'Message'}
            <i
              className={`ml-2 fas${
                messageState
                  ? ' fa-check-circle theme-primary'
                  : ' fa-exclamation-circle theme-error'
              }`}
            />
          </span>
        </ModalHeader>
        <ModalBody>
          {!successMsg
            ? 'Do You want to close this Alert, then click on the'
            : successMsg}
        </ModalBody>
        <ModalFooter>
          {showCancelButton ? (
            <button
              onClick={toggleAlertModal}
              type="button"
              className="btn-theme theme-cancel"
            >
              {cancelMsg || 'Cancel'}
            </button>
          ) : (
            ''
          )}
          <button
            onClick={onConfirm || toggleAlertModal}
            type="button"
            className="btn-theme btn-theme-success"
          >
            {okButtonText || 'OK'}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

AlertMessage.propTypes = {
  title: PropTypes.string,
  cancelMsg: PropTypes.string,
  onConfirm: PropTypes.func,
  messageState: PropTypes.bool,
  successMsg: PropTypes.string,
  okButtonText: PropTypes.string,
  toggleAlertModal: PropTypes.func,
  toggleAlertState: PropTypes.bool,
  showCancelButton: PropTypes.bool,
};

AlertMessage.defaultProps = {
  title: '',
  cancelMsg: 'Cancel',
  messageState: true,
  successMsg: 'Do You want to close this Alert, then click on the',
  okButtonText: 'OK',
  toggleAlertState: false,
  showCancelButton: true,
};

export default AlertMessage;
