import produce from 'immer';

import { CREATE_NOTIFICATION, REMOVE_NOTIFICATION } from '../actions/generic';

const initialState = {
  notificationId: null,
};

export default (state = initialState, action) =>
  produce(state, draftState => {
    switch (action.type) {
      case CREATE_NOTIFICATION:
        draftState.notificationId = action?.payload?.id;
        return;

      case REMOVE_NOTIFICATION:
        draftState.notificationId = null;
        return;

      default:
        return;
    }
  });
