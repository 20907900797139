import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

const BreadcrumbSeparator = () => {
  return <i className="fas fa-chevron-right crumb-separator next-icon" />;
};

const renderLinks = (label, link) => {
  if (label === 'Home') {
    return (
      <Link key="home" to="/home">
        <i className="fas fa-home home-icon" />
      </Link>
    );
  }
  return (
    <Link key={label} to={link}>
      {label}
    </Link>
  );
};

const Breadcrumbs = ({ crumbMapping = [] }) => {
  return (
    <div className="breadcrumbs">
      <Breadcrumb tag="nav" listTag="div">
        {crumbMapping.map(({ label, link }) => (
          <React.Fragment key={label}>
            <BreadcrumbItem>
              {link ? renderLinks(label, link) : <span>{label}</span>}
            </BreadcrumbItem>
            {link ? <BreadcrumbSeparator /> : ''}
          </React.Fragment>
        ))}
      </Breadcrumb>
    </div>
  );
};

Breadcrumbs.propTypes = {
  crumbMapping: PropTypes.arrayOf(PropTypes.object),
};

Breadcrumbs.defaultProps = {
  crumbMapping: [],
};

export default Breadcrumbs;
