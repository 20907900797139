import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs } from '../index';

const PageLayout = props => {
  const {
    children,
    className,
    headerText,
    crumbMapping,
    subHeaderText,
    renderRightContent,
    renderHeader,
    cardAfterBreadCrumb,
  } = props;
  return (
    <div className={className}>
      <section className="page-section">
        {renderHeader && <div>{renderHeader()}</div>}
        <div className="page-header">
          <Breadcrumbs crumbMapping={crumbMapping} />

          <div>
            <div className="title-wrapper" />
          </div>
          <h1>
            {headerText}
            {cardAfterBreadCrumb.length > 0 &&
              cardAfterBreadCrumb[0].showCard && (
                <span className="header-id">{cardAfterBreadCrumb[0].text}</span>
              )}
          </h1>

          {subHeaderText && <p>{subHeaderText}</p>}
          {renderRightContent && (
            <div className="page-header-right">{renderRightContent()}</div>
          )}
        </div>
        <div className="page-body">{children}</div>
      </section>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  headerText: PropTypes.string,
  crumbMapping: PropTypes.arrayOf(PropTypes.object),
  subHeaderText: PropTypes.string,
  renderRightContent: PropTypes.func,
  renderHeader: PropTypes.func,
  cardAfterBreadCrumb: PropTypes.arrayOf(PropTypes.object),
};

PageLayout.defaultProps = {
  children: null,
  className: '',
  headerText: '',
  crumbMapping: [],
  subHeaderText: '',
  renderRightContent: () => {},
  renderHeader: null,
  cardAfterBreadCrumb: [],
};

export default PageLayout;
