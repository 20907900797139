import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import cx from 'classnames';
import { ErrorMessage } from 'formik';
import moment from 'moment';

const modifiedTime = (dateToExtractDate, dateToExtractTime) => {
  const mTime = moment(dateToExtractTime);

  return moment(dateToExtractDate)
    .set({
      hours: mTime.hours(),
      minutes: mTime.minutes(),
      seconds: mTime.seconds(),
    })
    .toDate();
};

const TimePicker = ({
  label,
  name,
  required,
  showIcon,
  additionalOnChange,
  value,
  date,
  minimumTime,
  maximumTime,
  formInput,
}) => {
  const thisDate = value ? moment(value) : date ? moment(date) : moment();

  const [startDate, setStartDate] = useState(thisDate.toDate());
  useEffect(() => setStartDate(thisDate.toDate()), [date, value, thisDate]);

  const minTime =
    minimumTime ||
    moment(thisDate).set({ hours: 0, minutes: 0, seconds: 0 }).toDate();
  const maxTime =
    maximumTime ||
    moment(thisDate).set({ hours: 23, minutes: 59, seconds: 59 }).toDate();

  return (
    <div
      className={cx(
        'datepicker-wrapper timepicker-wrapper input-field date-input-field',
        { 'with-icon': showIcon }
      )}
    >
      {label && (
        <label htmlFor={name}>
          {label} <span>{required && '*'}</span>
        </label>
      )}

      <DatePicker
        selected={startDate}
        onChange={date => {
          const modifiedDate = modifiedTime(thisDate, date);

          setStartDate(modifiedDate);

          if (additionalOnChange) additionalOnChange(modifiedDate);
        }}
        value={value || undefined}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="hh:mm aa"
        minTime={minTime}
        maxTime={maxTime}
      />

      {formInput && (
        <div className="error-msg">
          <ErrorMessage name={name} />
        </div>
      )}
    </div>
  );
};

export default TimePicker;
