import compact from 'lodash/compact';
import moment from 'moment';
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';
import { numberFormat } from '../constants/phone';
import qs from 'query-string';
import pick from 'lodash/pick';
import jwt_decode from 'jwt-decode';
import forIn from 'lodash/forIn';
import _ from 'lodash';

const env =
  process.env.REACT_APP_ENVIRONMENT !== 'production'
    ? process.env.REACT_APP_ENVIRONMENT !== 'uat'
      ? process.env.REACT_APP_ENVIRONMENT + '.'
      : 'temp.'
    : '';

export const getUserDisplayName = (user, options = {}) => {
  const { skipMiddleName } = options;
  let names = ['first_name', 'middle_name', 'last_name'];
  if (skipMiddleName) {
    names.splice(1, 1);
  }
  const displayName = compact(Object.values(pick(user || {}, names)));

  return displayName.length > 0 ? displayName.join(' ') : '-';
};

export const memoriesLink = (decedent, opiId) => {
  let urlName = '';
  if (_.get(decedent, 'first_name')) {
    urlName += _.camelCase(_.get(decedent, 'first_name'));
  }
  if (urlName && _.get(decedent, 'last_name')) {
    urlName += `-${_.camelCase(_.get(decedent, 'last_name'))}`;
  } else {
    urlName += _.get(decedent, 'last_name');
  }
  if (urlName) {
    urlName += '-obituary';
  }
  const query = `https://${env}clcafamily.com/share-memories/${urlName}/${opiId}`;
  window.open(query, '_blank');
};

export const getInfo = decedent => {
  const dateFormat = 'MM-DD-YYYY';
  if (decedent?.date_of_death) {
    if (decedent?.date_of_birth) {
      return `${moment(decedent?.date_of_birth).format(dateFormat)} - ${moment(
        decedent?.date_of_death
      ).format(dateFormat)} |`;
    } else {
      return `Died : ${moment(decedent?.date_of_death).format(dateFormat)} |`;
    }
  } else if (decedent?.date_of_service) {
    return `Service : ${moment(decedent?.date_of_service).format(
      dateFormat
    )} |`;
  } else if (decedent?.date_of_birth) {
    return `Birth : ${moment(decedent?.date_of_birth).format(dateFormat)} |`;
  } else {
    return '';
  }
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const resetForm = formId => {
  document.getElementById(formId).reset();
};

export const getUserDetails = () => {
  return JSON.parse(localStorage.getItem('user'));
};

export const getFileExtension = fileName => {
  const arr = fileName.split('.');
  return arr[arr.length - 1].toLowerCase();
};

export const getPropertyDisplayName = (
  property,
  placeholder = '--',
  delimiter = ' | '
) => {
  const { garden, lot_section_panel, row_tier_division, niche_grave_crypt } =
    property;

  let attributes = [
    garden?.name || placeholder,
    lot_section_panel || placeholder,
    row_tier_division || placeholder,
    niche_grave_crypt || placeholder,
  ];

  return attributes.join(delimiter);
};

export const formatDate = (
  date,
  format = 'MM/DD/YYYY',
  inputFormat = moment.defaultFormat,
  invalidFormat = '-'
) => {
  if (!date || String(date).toLowerCase().includes('invalid')) {
    return invalidFormat;
  }
  const formattedDate = moment(date).format(format);
  return formattedDate.toLowerCase().includes('invalid')
    ? invalidFormat
    : formattedDate;
};

export const getDate = () => {
  const date = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ];
  return date.map(value => {
    return { value: value !== '00' ? value : null, label: value };
  });
};

export const getMonth = () => {
  return [
    { value: null, label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
  ];
};

export const checkEllipsis = el => {
  return el.scrollHeight > el.offsetHeight;
};

export const trimString = comment => {
  return comment.replace(/(\r\n|\n|\r)/gm, ' ').trim();
};

export const setTrimmedValueFactory = (
  fieldName,
  setFieldValue,
  allowSpecialCharacters = true,
  allowedCharacters = [],
  onlyCharacter = false,
  skipDot = false,
  onlyNumber = false
) => {
  return event => {
    if (!allowSpecialCharacters) {
      if (onlyCharacter) {
        if (isAlphabetic(event.target.value, allowedCharacters, skipDot)) {
          setFieldValue(fieldName, event.target.value);
        }
      } else {
        if (onlyNumber) {
          if (isNumeric(event.target.value, skipDot))
            setFieldValue(fieldName, event.target.value);
        } else {
          if (isAlphaNumeric(event.target.value, allowedCharacters, skipDot)) {
            setFieldValue(fieldName, event.target.value);
          }
        }
      }
    } else {
      setFieldValue(fieldName, event.target.value);
    }
  };
};

export const getAddressDisplayName = (
  address,
  fields = ['address1', 'address2']
) => {
  const addressValue = compact(Object.values(pick(address || {}, fields)));

  return addressValue.length > 0 ? addressValue.join(', ') : '-';
};

export const formatPhoneNumber = (
  phone,
  format = numberFormat.NATIONAL,
  country = 'US'
) => {
  const phoneNumber = parsePhoneNumberFromString(phone, country);
  return phoneNumber.format(format);
};

export const formatPhoneNumberContact = phone => {
  let removedPart = '';
  let cleanedPhone = phone;
  const firstFiveChars = phone.substring(0, 5);
  if (!firstFiveChars.match(/[-\s]/)) {
    removedPart = '';
    cleanedPhone = phone;
  } else {
    removedPart = phone.match(/^([^-\s]*[-\s]?)/)[0];
    cleanedPhone = phone.replace(/^[^-\s]*[-\s]?/, '');
  }
  const firstTenDigits = cleanedPhone.substring(0, 10);
  const formattedNumber = `${removedPart}(${firstTenDigits.substring(
    0,
    3
  )}) ${firstTenDigits.substring(3, 6)}-${firstTenDigits.substring(
    6,
    10
  )}${cleanedPhone.substring(10)}`;
  return formattedNumber;
};

export const formatPhoneNumberInput = phone => {
  const asYouType = new AsYouType('US');
  if (phone.includes('(') && !phone.includes(')')) {
    return phone;
  } else {
    return asYouType.input(phone);
  }
};
export const formatPhoneNumberUS = phoneNumberString => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

const getFormattedCurrency = (number, currency = 'USD') => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(
    number
  );
};
export const decoratedCurrency = value => {
  return value !== null && value > -1 ? getFormattedCurrency(value) : '-';
};

export const buildPanoramicViewURL = query =>
  qs.stringifyUrl({
    url: process.env.REACT_APP_PANORAMIC_VIEW_BASE_URL,
    query,
  });

export const buildGardenMapDetailsParams = (
  propertyId,
  gardenId,
  lotSectionPanel,
  rowTierDivision,
  nicheGraveCrypt
) => {
  return {
    ...(propertyId ? { id: propertyId } : {}),
    ...(gardenId ? { garden_id: gardenId } : {}),
    ...(lotSectionPanel ? { lot_section_panel: lotSectionPanel } : {}),
    ...(rowTierDivision ? { row_tier_division: rowTierDivision } : {}),
    ...(nicheGraveCrypt ? { niche_grave_crypt: nicheGraveCrypt } : {}),
  };
};

//Faster than regex
export const isAlphaNumeric = (str, specialCharacters = [], skipDot) => {
  let code, i, len;
  len = str.length;
  const specialCharCodes = specialCharacters.map(e => e.charCodeAt(0));

  for (i = 0; i < len; i++) {
    code = str.charCodeAt(i);

    if (skipDot) {
      if (
        specialCharCodes.indexOf(code) === -1 &&
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123) // lower alpha (a-z)
      ) {
        return false;
      }
    } else {
      if (
        specialCharCodes.indexOf(code) === -1 &&
        !(code === 32 || code === 46) && //charcode for dot and space
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123) // lower alpha (a-z)
      ) {
        return false;
      }
    }
  }

  return true;
};

export const isNumeric = (str, skipDot) => {
  let code, i, len;
  len = str.length;

  for (i = 0; i < len; i++) {
    code = str.charCodeAt(i);

    if (skipDot) {
      if (
        !(code > 47 && code < 58) // numeric (0-9)
      ) {
        return false;
      }
    } else {
      if (
        !(code === 32 || code === 46) && //charcode for dot and space
        !(code > 47 && code < 58) // numeric (0-9)
      ) {
        return false;
      }
    }
  }

  return true;
};

//Faster than regex
export const isAlphabetic = (str, specialCharacters = []) => {
  let code, i, len;
  len = str.length;
  const specialCharCodes = specialCharacters.map(e => e.charCodeAt(0));
  for (i = 0; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      specialCharCodes.indexOf(code) === -1 &&
      !(code === 32 || code === 46) && //charcode for dot and space
      // !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123) // lower alpha (a-z)
    ) {
      return false;
    }
  }
  return true;
};

export const getPlattedRecordURL = ({ book_pages_count, ...obj }) => {
  const queryObj = {
    ...obj,
    count: book_pages_count,
  };
  return qs.stringifyUrl({
    url: '/platted-records/record-details',
    query: queryObj,
  });
};

export const isSessionExpired = token => {
  if (token) {
    const details = jwt_decode(token);
    const expiry = moment.unix(details.exp);
    return new Date() > expiry._d;
  }
  return true;
};

export const clearStorageAndRedirect = () => {
  localStorage.clear();
  window.location.href = '/login';
};

export const dataURItoBlob = dataURI => {
  let byteString;
  let mimeString;
  let intArray;

  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = encodeURI(dataURI.split(',')[1]);
  }
  // separate out the mime component
  mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  intArray = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }
  return new Blob([intArray], { type: mimeString, charset: 'UTF-8' });
};

export const isObjectEmpty = obj => {
  return (
    Object.values(obj).filter(e => {
      return typeof e === 'string' ? !!e.trim() : !!e;
    }).length === 0
  );
};

export const getTrimmedObject = ({ ...obj }, removeEmpty = false) => {
  for (let property in obj) {
    if (typeof obj[property] === 'string') obj[property] = obj[property].trim();
    if (removeEmpty && !obj[property]) {
      delete obj[property];
    }
  }
  return obj;
};

export const pluralize = (
  number = 0,
  singular,
  plural,
  customMessage = false
) => {
  return number === 0
    ? customMessage
      ? customMessage
      : 'No Records Found'
    : number === 1
    ? `${number} ${singular}`
    : `${number} ${plural}`;
};

export const isFileImage = file => {
  return file && file['type'].split('/')[0] === 'image';
};

export const isFileSizeExceeded = (file, limit) => {
  const fileSize = file.size;
  const sizeLimit = limit * (1024 * 1024);
  return fileSize > sizeLimit;
};

export const isStagingEnvironment = () => {
  return process.env.REACT_APP_ENVIRONMENT === 'staging';
};

export const isUatEnvironment = () => {
  return process.env.REACT_APP_ENVIRONMENT === 'uat';
};

export const isProductionEnvironment = () => {
  return process.env.REACT_APP_ENVIRONMENT === 'production';
};

export const resetNavItems = (section, tile) => {
  // reset other options
  forIn(tile, (value, key) => {
    if (key !== section) {
      // to check if it has further nesting
      if (!value.hasOwnProperty('open')) {
        forIn(value, nest => {
          nest.setIsSelected(false);
        });
      } else {
        value.setIsSelected(false);
      }
    }
  });
};

export const decorate = type => {
  switch (type) {
    case 'Decent':
      return 'Deceased';

    default:
      return type;
  }
};

export const getDisplayPermission = permissions => {
  if (permissions !== undefined) {
    let result = [];
    permissions.map(el =>
      el.name.includes('view')
        ? result.push('R')
        : el.name.includes('update')
        ? result.push('W')
        : el.name.includes('delete')
        ? result.push('D')
        : null
    );

    return result.length > 1 ? result.join('/') : result[0];
  } else if (permissions === undefined) {
    return 'N/A';
  } else {
    return '-';
  }
};

export const getDropdownOption = permissions => {
  if (permissions) {
    let result = [];
    let options = [{ label: 'N/A', value: 'N/A', valueArray: '' }];
    permissions.map(el =>
      el.name.includes('view')
        ? result.push({ label: 'R', value: `${el.id}`, valueArray: el.id })
        : el.name.includes('update')
        ? result.push({ label: 'W', value: `${el.id}`, valueArray: el.id })
        : el.name.includes('delete')
        ? result.push({ label: 'D', value: `${el.id}`, valueArray: el.id })
        : null
    );
    const readIndex = result.findIndex(value => value.label === 'R');
    for (let i = 0; i < result.length; i++) {
      if (i !== readIndex) {
        options.push({
          label: `${result[readIndex].label}/${result[i].label}`,
          value: `${[result[readIndex].value, result[i].value]}`,
          valueArray: [result[readIndex].valueArray, result[i].valueArray],
        });
      } else {
        options.push(result[i]);
      }
    }
    if (result.length === 3) {
      options.push({
        label: result.map(el => el.label).join('/'),
        value: `${result.map(el => el.value)}`,
        valueArray: result.map(el => el.valueArray),
      });
    }
    return options;
  } else {
    return '-';
  }
};

export const getAvailableRights = availableRights => {
  const selectRightsOptions = [];
  if (availableRights > 0) {
    for (let index = 1; index <= availableRights; index++) {
      selectRightsOptions.push({
        label: index,
        value: index,
      });
    }
  }

  return selectRightsOptions;
};

export const displayReserveFromStatus = status => {
  return (
    status?.toLowerCase() === 'for sale' ||
    status?.toLowerCase() === 'occupied' ||
    status?.toLowerCase() === 'reserved' ||
    status?.toLowerCase() === 'sold'
  );
};
