import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Panel from '../panel/Panel';
import StatusBox from '../status-box/status-box';

export const displayFooter = props => {
  const { footerText, fieldsOk } = props;

  if (footerText) {
    return <StatusBox statusText={footerText} allFieldsComplete={fieldsOk} />;
  }
};

const InfoPanel = props => {
  const {
    primary,
    children,
    leftIcon,
    rightIcon,
    headerText,
    showHeader,
    panelHeaderRender,
    containerClassName,
  } = props;

  return (
    <Panel
      primary={primary}
      renderHeader={panelHeaderRender}
      footer={displayFooter(props)}
      containerClassName={cx('info-panel', containerClassName)}
      headerText={headerText}
      rightIcon={rightIcon}
      leftIcon={leftIcon}
      showHeader={showHeader}
    >
      <div className="info-panel-section">{children}</div>
    </Panel>
  );
};

InfoPanel.propTypes = {
  primary: PropTypes.bool,
  children: PropTypes.node,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  headerText: PropTypes.string,
  showHeader: PropTypes.bool,
  panelHeaderRender: PropTypes.func,
  containerClassName: PropTypes.string,
};

InfoPanel.defaultProps = {
  primary: false,
  children: null,
  leftIcon: '',
  rightIcon: '',
  headerText: '',
  showHeader: false,
  panelHeaderRender: () => {},
  containerClassName: '',
};

export default InfoPanel;
