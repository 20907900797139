import React, { useCallback, useEffect, useState } from 'react';
import useAsync from 'react-use-async-hook';
import cx from 'classnames';

import { updateStatus } from '../../../api/reserve';
import { RESERVATION_PROPERTY_STATUS } from '../../../constants/reservation-property';

import { Button, Select } from '../../../common/components';
import Edit from '../../../assets/images/edit.png';
import ReservationUpdatePopup from '../../../features/property/property-details/reservation-update-popup';
import { apiErrorHandler } from '../../../utils/handlers';

const CardItemUpdateReservation = ({
  propertyId,
  name,
  value,
  horizontal,
  property,
  className,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [updateReservationModal, setUpdateReservationModal] = useState(false);
  const allowedStatuses = RESERVATION_PROPERTY_STATUS.map(item => item.value);

  const disableSaveBtn = value === selectedStatus.label;
  const hideEditBtn = allowedStatuses.includes(value);

  const {
    loading: updateReservationLoading,
    execute: updateReservationStatus,
  } = useAsync({
    executeOnChange: false,
    executeOnLoad: false,
    task: useCallback(
      reason => {
        let data = {
          property: {
            grave_status: selectedStatus.value,
            comments_attributes: [
              {
                description: reason,
              },
            ],
          },
        };
        return updateStatus(propertyId, data);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [property, propertyId, selectedStatus?.value]
    ),
    dataLoader: useCallback(response => {
      const data = response?.data?.data;

      setSelectedStatus({
        label: data?.grave_status,
        value: data?.grave_status,
      });

      setUpdateReservationModal(false);
      setEdit(false);
      window.location.reload();

      return response?.data;
    }, []),
    onError: apiErrorHandler,
  });

  const toggleUpdateReservation = () =>
    setUpdateReservationModal(!updateReservationModal);

  useEffect(() => {
    setSelectedStatus({
      label: value,
      value,
    });
    setEdit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId]);

  const handleEdit = value => setEdit(value);

  const handleCancel = () => {
    handleEdit(false);
    setSelectedStatus({
      label: value,
      value,
    });
  };

  return (
    <div
      className={cx(
        'item-card',
        { 'item-card-horizontal': horizontal },
        className
      )}
    >
      <div className="super-admin-reserve-status">
        <span className="item-card-name">{name}</span>
        {!isEdit ? (
          hideEditBtn && (
            <Button
              kind="text"
              className="edit-btn"
              textTransform="pascalcase"
              onClick={() => handleEdit(true)}
            >
              <img src={Edit} className="img" alt="edit" />
              Edit
            </Button>
          )
        ) : (
          <div className="save-container">
            <Button
              kind="text"
              color="primary"
              onClick={toggleUpdateReservation}
              textTransform="pascalcase"
              disabled={disableSaveBtn}
            >
              Save
            </Button>
            <Button kind="text" color="default" onClick={handleCancel}>
              <i class="fas fa-times cancel-btn"></i>
            </Button>
          </div>
        )}
      </div>

      {isEdit ? (
        <div className="reservation-status-dropdown-container">
          <Select
            value={selectedStatus}
            options={RESERVATION_PROPERTY_STATUS}
            onChange={option => {
              setSelectedStatus({
                label: option.label,
                value: option.value,
              });
            }}
            formInput={false}
          />
        </div>
      ) : (
        <span className="item-card-value">{value}</span>
      )}

      <ReservationUpdatePopup
        toggle={toggleUpdateReservation}
        isOpen={updateReservationModal}
        handleUpdate={updateReservationStatus}
        loading={updateReservationLoading}
      />
    </div>
  );
};

export default CardItemUpdateReservation;
