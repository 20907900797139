import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button } from '../../../common/components';

const CollapseRightIcon = ({
  icon,
  headerButton,
  handleEdit,
  handleSave,
  handleCancel,
}) => {
  return (
    <>
      <span className="panel-header-btn panel-header-btn-right">
        {headerButton && (
          <>
            {handleEdit && (
              <Button
                color="secondary"
                kind="text"
                className="mr-3"
                onClick={e => {
                  e.stopPropagation();
                  handleEdit();
                }}
              >
                EDIT
              </Button>
            )}
            {handleSave && (
              <Button
                color="primary"
                kind="text"
                className="mr-3"
                onClick={e => {
                  e.stopPropagation();
                  handleSave();
                }}
              >
                SAVE
              </Button>
            )}
            {handleCancel && (
              <Button
                color="secondary"
                kind="text"
                className="mr-3"
                onClick={e => {
                  e.stopPropagation();
                  handleCancel();
                }}
              >
                CANCEL
              </Button>
            )}
          </>
        )}
        <i
          className={cx(
            'panel-header-icon',
            'panel-header-icon-right',
            'fas',
            icon,
            'ml-4'
          )}
        />
      </span>
    </>
  );
};

CollapseRightIcon.propTypes = {
  icon: PropTypes.string,
};

CollapseRightIcon.defaultProps = {
  icon: '',
};

const Panel = props => {
  const {
    containerClassName,
    onHeaderClick,
    headerClassName,
    bodyClassName,
    footerClassName,
    children,
    footer,
    rightIcon,
    leftIcon,
    primary,
    showHeader,
    renderHeader,
    headerButton,
    handleEdit,
    handleSave,
    handleCancel,
  } = props;

  const isHeaderNotString = props?.isHeaderNotString;
  const defaultHeaderRenderer = (headerText, rightIcon, leftIcon) => {
    return (
      <div className="panel-header-text-wrap">
        {leftIcon && (
          <i
            className={cx(
              'panel-header-icon',
              'panel-header-icon-left',
              'fas',
              leftIcon
            )}
          />
        )}

        {isHeaderNotString ? headerText : <span>{headerText}</span>}

        {rightIcon && (
          <CollapseRightIcon
            icon={rightIcon}
            headerButton={headerButton}
            handleEdit={handleEdit}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        )}
      </div>
    );
  };

  const _renderHeader = (rightIcon, leftIcon) => {
    if (props.renderHeader) {
      return props.renderHeader();
    }
    return defaultHeaderRenderer(props.headerText, rightIcon, leftIcon);
  };

  const displayHeader = showHeader || renderHeader;

  return (
    <div className={cx('panel-container', containerClassName)}>
      {displayHeader && (
        <div
          className={cx(
            'panel-header',
            { 'panel-primary': primary },
            headerClassName
          )}
          onClick={onHeaderClick}
          role="button"
          tabIndex="-42"
          onKeyUp={onHeaderClick}
        >
          {_renderHeader(rightIcon, leftIcon)}
          {renderHeader && rightIcon && <CollapseRightIcon icon={rightIcon} />}
        </div>
      )}
      <div className={cx('panel-body', bodyClassName)}>{children}</div>
      {footer && (
        <div className={cx('panel-footer', footerClassName)}>{footer}</div>
      )}
    </div>
  );
};

Panel.propTypes = {
  // Main
  renderHeader: PropTypes.func,
  headerText: PropTypes.string,
  children: PropTypes.node,
  footer: PropTypes.node,

  // Icons
  rightIcon: PropTypes.string,
  leftIcon: PropTypes.string,

  // Default Styling
  primary: PropTypes.bool,
  showHeader: PropTypes.bool,

  // Custom styles
  containerClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  footerClassName: PropTypes.string,

  // Events
  onHeaderClick: PropTypes.func,
};

Panel.defaultProps = {
  // Main
  renderHeader: null,
  headerText: '',
  children: null,
  footer: null,

  // Icons
  rightIcon: '',
  leftIcon: '',

  // Default Styling
  primary: false,
  showHeader: false,

  // Custom styles
  containerClassName: '',
  headerClassName: '',
  bodyClassName: '',
  footerClassName: '',

  // Events
  onHeaderClick: () => {},
};

export default Panel;
