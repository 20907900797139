/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import classNames from 'classnames';

const DatalistInput = ({
  name,
  label,
  required,
  placeholder,
  type,
  size,
  options,
  setSearchedLocation,
  showSearchedOptions,
  openMenu,
  setMenu,
  searchText,
  setSearchText,
  searchedText,
  ...rest
}) => {
  const selected = false;
  return (
    <div className="datalist-input-container">
      <div className="input-field">
        <label htmlFor={name}>
          {label} <span>{required ? '*' : ''}</span>
        </label>
        <input
          value={options?.length > 0 ? searchedText : searchText}
          className={classNames('input', size)}
          required={required}
          type={type}
          name={name}
          placeholder={placeholder}
          autocomplete="off"
          {...rest}
        />
      </div>
      {options && (
        <div className="datalist-items">
          {openMenu &&
            searchedText &&
            showSearchedOptions &&
            !options.find(
              item =>
                item.label.split(',')[0]?.trim()?.toLowerCase() ===
                searchedText.trim()?.toLowerCase()
            ) && (
              <li
                onClick={() => {
                  if (setMenu) {
                    setMenu(false);
                  }
                  setSearchedLocation(searchedText);
                }}
                key={searchedText}
                className={cx({ 'active-item': selected })}
              >
                {searchedText}
              </li>
            )}
          {options.map(item => {
            return (
              <li
                onClick={() => {
                  if (setMenu) {
                    setMenu(false);
                  }
                  setSearchedLocation(item);
                }}
                key={item?.label}
                className={cx({ 'active-item': selected })}
              >
                {item.label}
              </li>
              // Provide the class "active-item" for selected item
            );
          })}
        </div>
      )}
    </div>
  );
};

DatalistInput.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
  type: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};

DatalistInput.defaultProps = {
  name: '',
  size: 'lg',
  type: 'text',
  label: '',
  disabled: false,
  required: false,
  placeholder: '',
};

export default DatalistInput;
