import React from 'react';
import PropTypes from 'prop-types';

const Layout = props => {
  const { children } = props;
  return (
    <>
      <div className="layout-content">{children}</div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
