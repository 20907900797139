import notificationConstants from '../constants/notification-constants';
import { store } from 'react-notifications-component';
import reduxStore from '../store';
import {
  createNotification,
  removeNotification as removeReduxNotification,
} from '../actions/generic';
import 'animate.css/animate.compat.css';
import errorMessagesMap from '../error-handler';
import { msalInstance } from '../index';

export const successHandler = (message, options = {}) => {
  addNotificationToStore({
    ...notificationConstants.SUCCESS,
    message,
    ...options,
  });
};

export const warningHandler = (message, options = {}) => {
  addNotificationToStore({
    ...notificationConstants.WARNING,
    message,
    ...options,
  });
};

export const errorHandler = (message, options = {}) => {
  addNotificationToStore({
    ...notificationConstants.ERROR,
    message,
    ...options,
  });
};

const getOldNotificationId = () => {
  return reduxStore.getState().generic?.notificationId;
};

export const removeStoredNotification = () => {
  const oldNotificationId = getOldNotificationId();
  oldNotificationId && store.removeNotification(oldNotificationId);
};

const addNotificationToStore = notificationObj => {
  removeStoredNotification();
  reduxStore.dispatch(
    createNotification({
      id: store.addNotification({
        ...notificationConstants.COMMON_SETTINGS,
        ...notificationObj,
        onRemoval: id => {
          if (getOldNotificationId() === id)
            reduxStore.dispatch(removeReduxNotification());
        },
      }),
    })
  );
};

const fallbackErrorMessage = 'Some error occured. Please try after sometime';

export const apiErrorHandler = (error, apiKey = null) => {
  if (error.code === 'ECONNABORTED') {
    errorHandler('Search request timeout! Please try with more details.');
    return;
  }
  const data = error?.response?.data;
  const beMessage = data?.message;
  const shouldPrioritizeMessage = data?.prioritize_message;

  console.log('errorerror1', data);
  console.log('errorerror2', beMessage);

  const messagesObj = errorMessagesMap[apiKey];

  let errorMessage = fallbackErrorMessage;

  const feMessage = messagesObj && messagesObj[data?.code];

  if (beMessage && shouldPrioritizeMessage) {
    errorMessage = beMessage;
  } else if (feMessage) {
    errorMessage = feMessage;
  } else if (beMessage) {
    errorMessage = beMessage;
  }

  errorHandler(Array.isArray(errorMessage) ? errorMessage[0] : errorMessage);
};

export const getTokenFromLocalStorage = () => localStorage.getItem('token');

export const getPermissionsFromLocalStrorage = () => {
  return JSON.parse(localStorage.getItem('permissions'));
};

export const getUserFromLocalStrorage = () => {
  return JSON.parse(localStorage.getItem('user'));
};

export const filterItems = (option, text) => {
  return option?.data.content?.toLowerCase().includes(text.toLowerCase())
    ? true
    : false;
};

export const getAuthToken = async () => {
  try {
    const accounts = await msalInstance.getAllAccounts();

    const accessTokenRequest = {
      scopes: ['user.read'],
      account: accounts[0],
      forceRefresh: true,
    };

    const accessTokenResponse = await msalInstance.acquireTokenSilent(
      accessTokenRequest
    );

    const accessToken = accessTokenResponse.idToken;
    return accessToken;
  } catch {
    handleAuthLogout();
    return null;
  }
};

export const handleAuthLogout = async () => {
  const accounts = msalInstance.getAllAccounts();
  const logoutHint = accounts[0]?.idTokenClaims.login_hint;
  msalInstance.logoutRedirect({
    postLogoutRedirectUri: '/',
    logoutHint,
  });
  localStorage.clear();
};
