export default {
  getDeceasedById: {
    2001: 'No OPI ID present for this decedent.',
  },

  removeBookPageLink: {
    2000: 'Platted record is not linked',
    2001: 'Link is already removed',
    2002: 'Error in removing platted record link',
  },

  uploadFile: {
    2000: 'Please provide the correct file',
    2001: 'Format of file is not compatible',
    2002: 'Error while uploading property GeoJSON file',
    2003: 'Garden GeoJSON upload failed due to validation error',
  },
};
