import axios from './axios';

export async function getContracts(contractId, params) {
  return axios({
    method: 'GET',
    url: `/contracts/${contractId}`,
    params,
  });
}

export async function getContractsAddendum(cl_ref, params) {
  return axios({
    method: 'GET',
    url: `/purchasers/${cl_ref}/contracts`,
    params,
  });
}

export async function getAllContracts(contractId, params) {
  return axios({
    method: 'GET',
    url: `/properties/${contractId}/all_contracts`,
    params,
  });
}

export async function reserve(id, data) {
  return axios({
    method: 'PUT',
    url: `/properties/${id}/reserve`,
    data,
  });
}

export async function updateStatus(id, data) {
  return axios({
    method: 'PUT',
    url: `/properties/${id}/update_status`,
    data,
  });
}
