/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import cx from 'classnames';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerReact = ({
  label,
  formInput,
  name,
  required,
  showIcon,
  placeholder,
  options,
  selected,
  onChangeRaw,
  ...rest
}) => {
  return (
    <div
      className={cx('datepicker-wrapper input-field date-input-field', {
        'with-icon': showIcon,
      })}
    >
      {label && (
        <label htmlFor={name}>
          {label} <span>{required ? '*' : ''}</span>
        </label>
      )}
      <div className="daterange-container w-100">
        <DatePicker
          {...rest}
          onChangeRaw={onChangeRaw}
          selected={selected ? moment(selected).toDate() : null}
          placeholderText={placeholder}
          showMonthDropdown
          showYearDropdown
          fixedHeight
          dropdownMode="select"
        />
      </div>
      {formInput && (
        <div className="error-msg">
          <ErrorMessage name={name} />
        </div>
      )}
    </div>
  );
};
DatePickerReact.propTypes = {
  formInput: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showIcon: PropTypes.bool,
  options: PropTypes.object,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChangeRaw: PropTypes.func,
};
DatePickerReact.defaultProps = {
  formInput: true,
  disabled: false,
  required: false,
  label: '',
  showIcon: false,
  placeholder: 'Select Date',
  options: {},
  selected: null,
  onChangeRaw: e => e.preventDefault(),
};
export default DatePickerReact;
