import React, { useState, useEffect } from 'react';
import { store } from 'react-notifications-component';
import PropTypes from 'prop-types';
import notificationConstants from '../../../constants/notification-constants';

const Notification = ({ errorResponse }) => {
  const [error, setError] = useState({});
  useEffect(() => {
    if (errorResponse) {
      setError(errorResponse?.response);
    }
  }, [errorResponse]);
  console.log(error?.response, 'response');
  return (
    <>
      {store.addNotification({
        ...notificationConstants.COMMON_SETTINGS,
        ...notificationConstants.ERROR,

        message:
          errorResponse?.response?.data?.message || 'SOMETHING WENT WRONG!',
      })}
    </>
  );
};

Notification.propTypes = {
  errorResponse: PropTypes.objectOf(PropTypes.object),
};

Notification.defaultProps = {
  errorResponse: {},
};

export default Notification;
