import React from 'react';
import PropTypes from 'prop-types';

const Heading = props => {
  const { headingText, children } = props;

  return (
    <div className="heading">
      <h5>{headingText}</h5>
      {children}
    </div>
  );
};

Heading.defaultProps = {
  children: null,
  headingText: '',
};

Heading.propTypes = {
  children: PropTypes.node,
  headingText: PropTypes.string,
};

export default Heading;

// We might not this, we can explore the Jumbotron element for reactstrap.
