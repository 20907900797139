export const RESERVATION_PROPERTY_STATUS = [
  {
    label: 'Admin Hold',
    value: 'Admin Hold',
  },
  {
    label: 'For Sale',
    value: 'For Sale',
  },
  {
    label: 'Obstruct',
    value: 'Obstruct',
  },
  {
    label: 'Pipe',
    value: 'Pipe',
  },
  {
    label: 'TB',
    value: 'TB',
  },
  {
    label: 'Tree',
    value: 'Tree',
  },
];
