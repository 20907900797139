/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Toggle from 'react-toggle';
import PropTypes from 'prop-types';

const ReactToggle = ({ checked, onChange, disabled }) => {
  return (
    <label>
      <Toggle
        defaultChecked={checked}
        icons={false}
        onChange={onChange}
        disabled={disabled}
      />
    </label>
  );
};

ReactToggle.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

ReactToggle.defaultProps = {
  disabled: false,
  checked: false,
};

export default ReactToggle;
