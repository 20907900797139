import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { Select } from '..';
import _ from 'lodash';

const perPageOptions = [5, 10, 15, 25, 50, 100];
const buildOption = x => ({ value: x, label: x });

const CommonPagination = props => {
  const {
    page,
    setPage,
    totalResults,
    limit,
    setLimit,
    pageName,
    limitOptions = [],
  } = props;
  const pageOptions = _.union(perPageOptions, limitOptions);

  const numPages = Math.ceil(totalResults / limit);
  const showingResultsFrom = (page - 1) * limit + 1;
  const showingResultsTo = Math.min(page * limit, totalResults);

  return (
    <div className="pagination-section">
      <div>
        <span className="pagination-label">Items per page</span>
        <div className="per-page">
          <Select
            name="limit"
            size="sm"
            formInput={false}
            options={pageOptions.map(buildOption)}
            value={buildOption(pageOptions.find(x => x === limit))}
            isSearchable={false}
            onChange={option => {
              setPage(1);
              setLimit(option.value);
            }}
          />
        </div>
      </div>
      <div>
        <span className="records-page">
          Showing:{' '}
          <span>
            {totalResults === 0 ? totalResults : showingResultsFrom} -{' '}
            {showingResultsTo} of {totalResults}
          </span>{' '}
          {pageName ? <span className="page-name">{pageName}</span> : null}
        </span>
        <Pagination size="sm" className="nav-pagination">
          <PaginationItem disabled={page === 1}>
            <PaginationLink
              previous
              onClick={e => {
                //TODO @pallavi check if using preventDefault can be avoided
                e.preventDefault();
                setPage(page - 1);
              }}
              disabled={page === 1}
            />
          </PaginationItem>
          <PaginationItem disabled={page === numPages || numPages === 0}>
            <PaginationLink
              next
              onClick={e => {
                //TODO @pallavi check if using preventDefault can be avoided
                e.preventDefault();
                setPage(page + 1);
              }}
              disabled={page === numPages || numPages === 0}
            />
          </PaginationItem>
        </Pagination>
      </div>
    </div>
  );
};

CommonPagination.propTypes = {
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setLimit: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  limitOptions: PropTypes.arrayOf(PropTypes.number),
};

CommonPagination.defaultProps = {
  pageName: '',
};

export default CommonPagination;
