import axios from 'axios';
import { clearStorageAndRedirect, isSessionExpired } from '../utils/global';
import {
  getTokenFromLocalStorage,
  getAuthToken,
  handleAuthLogout,
} from '../utils/handlers';
const isAzureMFAEnabled = process.env.REACT_APP_IS_AZURE_MFA_ENABLED;

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`,
  headers: {
    authentication: '',
  },
});

instance.interceptors.request.use(async config => {
  const customConfig = config;
  customConfig.headers = {
    'Content-Type': 'application/json',
  };

  if (config?.route !== 'public') {
    const token =
      isAzureMFAEnabled?.toLowerCase() === 'true'
        ? await getAuthToken()
        : getTokenFromLocalStorage();

    if (!token) clearStorageAndRedirect();

    const isSalesApp = JSON.parse(localStorage.getItem('user'))?.isSalesApp;
    if (!isSalesApp) {
      if (isSessionExpired(token) && config.url !== '/sign_in') {
        clearStorageAndRedirect();
      }
    }
    if (config.url !== '/sign_in' && token) {
      customConfig.headers.Authorization = `Bearer ${token}`;
    }
  }
  return customConfig;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      isAzureMFAEnabled?.toLowerCase() !== 'true'
        ? clearStorageAndRedirect()
        : setTimeout(() => {
            handleAuthLogout();
          }, 3000);
    }
    return Promise.reject(error);
  }
);

export default instance;
