import React, { useEffect } from 'react';
import qs from 'query-string';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loginSuccess } from '../../../actions/auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  /**
   * TODO: Logic for determining if user is logged in
   * In case if it's not, redirect to login page
   */
  const history = useHistory();
  const params = qs.parse(history.location.search);
  const dispatch = useDispatch();

  useEffect(() => {
    if (params?.isSalesApp && params?.token) {
      const user = {
        isLoggedIn: true,
        token: params?.token,
        isSalesApp: true,
        permissions: ['can_view_property', 'can_view_mapping'],
      };
      dispatch(loginSuccess(user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.isSalesApp, params?.token]);

  const { isLoggedIn } = useSelector(state => ({
    isLoggedIn: state.auth.isLoggedIn,
  }));

  return (
    <Route
      {...rest}
      render={props => {
        const { location } = props;
        if ((params?.isSalesApp && params?.token) || isLoggedIn) {
          return <Component {...props} />;
        } else {
          const redirectPath =
            location.pathname === '/map-search'
              ? `${location.pathname}${location.search}`
              : location.pathname;
          sessionStorage.setItem('redirectPath', redirectPath);
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

export default PrivateRoute;
