import React from 'react';
import cx from 'classnames';

const CardItem = ({ name, value, horizontal, className }) => {
  return (
    <div
      className={cx(
        'item-card',
        { 'item-card-horizontal': horizontal },
        className
      )}
    >
      <span className="item-card-name">{name}</span>
      <span className="item-card-value">{value}</span>
    </div>
  );
};

export default CardItem;
