import React from 'react';
import { useHistory } from 'react-router-dom';

export default props => {
  const history = useHistory();

  const onClickHandler = () => {
    if (props.url) {
      history.push(props.url);
      return;
    }
    history.goBack();
  };

  return (
    <div className="back-btn-wrapper">
      <button type="button" onClick={onClickHandler} className="back-btn">
        <i className="fas fa-arrow-left" />
        <span>BACK</span>
      </button>
    </div>
  );
};
