import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import TabHeaders from './tab-headers';

const Tabs = props => {
  const {
    headers,
    onTabSelect,
    activeTabIndex,
    showClose,
    pullLeft,
    onTabClose,
    showCloseForActive,
    children,
    paneContainerClassName,
    containerClassName,
    headerContainerClassName,
    headerClassName,
    headerLinkClassName,
    renderHeader,
    tabIndexClassName,
    skipCloseForFirstTab,
  } = props;
  return (
    <div className={cx('tab-container', containerClassName)}>
      <TabHeaders
        skipCloseForFirstTab={skipCloseForFirstTab}
        headers={headers}
        onTabSelect={onTabSelect}
        activeTabIndex={activeTabIndex}
        showClose={showClose}
        pullLeft={pullLeft}
        showCloseForActive={showCloseForActive}
        onTabClose={onTabClose}
        headerContainerClassName={headerContainerClassName}
        headerClassName={headerClassName}
        headerLinkClassName={headerLinkClassName}
        renderHeader={renderHeader}
        tabIndexClassName={tabIndexClassName}
      />
      <div className={cx('tab-pane-container', paneContainerClassName)}>
        {children[activeTabIndex]}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  // Header
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeTabIndex: PropTypes.number,
  showClose: PropTypes.bool,
  pullLeft: PropTypes.bool,
  showCloseForActive: PropTypes.bool,
  renderHeader: PropTypes.func,
  skipCloseForFirstTab: PropTypes.bool,

  // Tab Pane
  children: PropTypes.arrayOf(PropTypes.node),

  // Events
  onTabSelect: PropTypes.func,
  onTabClose: PropTypes.func,

  // Styling
  paneContainerClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  headerContainerClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  headerLinkClassName: PropTypes.string,
  tabIndexClassName: PropTypes.string,
};

Tabs.defaultProps = {
  activeTabIndex: 0,
  showClose: false,
  pullLeft: false,
  showCloseForActive: false,
  renderHeader: null,
  skipCloseForFirstTab: false,

  // Tab Pane
  children: [],

  // Events
  onTabSelect: () => {},
  onTabClose: () => {},

  // Styling
  paneContainerClassName: '',
  containerClassName: '',
  headerContainerClassName: '',
  headerClassName: '',
  headerLinkClassName: '',
  tabIndexClassName: '',
};

export const SimpleTabs = props => {
  const {
    headers,
    onTabSelect,
    pullLeft,
    children,
    tabIndexClassName,
    activeTabIndexValue,
  } = props;
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (activeTabIndexValue) {
      setActiveTabIndex(activeTabIndexValue);
    }
  }, [activeTabIndexValue]);

  return (
    <Tabs
      headers={headers}
      activeTabIndex={activeTabIndex}
      onTabSelect={(index, e) => {
        setActiveTabIndex(index);
        onTabSelect(index, e);
      }}
      tabIndexClassName={tabIndexClassName}
      pullLeft={pullLeft}
    >
      {children}
    </Tabs>
  );
};

SimpleTabs.propTypes = {
  // Header
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  pullLeft: PropTypes.bool,

  // Tab Pane
  children: PropTypes.arrayOf(PropTypes.node),

  // Events
  onTabSelect: PropTypes.func,
  tabIndexClassName: PropTypes.string,
  activeTabIndexValue: PropTypes.number,
};

SimpleTabs.defaultProps = {
  pullLeft: false,

  // Tab Pane
  children: [],

  // Events
  onTabSelect: () => {},
};

export default Tabs;
