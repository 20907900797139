import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Collapse } from 'reactstrap';
import Panel from './Panel';
import { displayFooter } from '../info-panel/index';

const CollapsiblePanel = props => {
  const {
    isOpen,
    leftIcon,
    children,
    headerText,
    renderHeader,
    bodyClassName,
    onHeaderClick,
    footerClassName,
    headerClassName,
    containerClassName,
    isHeaderNotString,
    headerButton,
    handleEdit,
    handleSave,
    handleCancel,
  } = props;

  let showHeader = false;

  if (headerText) {
    showHeader = true;
  }

  return (
    <Panel
      headerText={headerText}
      showHeader={showHeader}
      renderHeader={renderHeader}
      leftIcon={leftIcon}
      rightIcon={isOpen ? 'fa-angle-down' : 'fa-angle-right'}
      headerClassName={cx('cursor-pointer', headerClassName)}
      isHeaderNotString={isHeaderNotString}
      containerClassName={containerClassName}
      bodyClassName={bodyClassName}
      onHeaderClick={onHeaderClick}
      footer={displayFooter(props)}
      footerClassName={footerClassName}
      headerButton={headerButton}
      handleEdit={handleEdit}
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </Panel>
  );
};

export const SimpleCollapsiblePanel = props => {
  const {
    isOpen: isOpenProp = false,
    leftIcon,
    children,
    headerText,
    renderHeader,
    bodyClassName,
    onHeaderClick,
    footerClassName,
    headerClassName,
    containerClassName,
  } = props;

  const [isOpen, setIsOpen] = useState(isOpenProp);

  return (
    <CollapsiblePanel
      isOpen={isOpen}
      headerText={headerText}
      renderHeader={renderHeader}
      onHeaderClick={e => {
        setIsOpen(!isOpen);
        if (onHeaderClick) {
          onHeaderClick(e);
        }
      }}
      leftIcon={leftIcon}
      containerClassName={containerClassName}
      headerClassName={headerClassName}
      bodyClassName={bodyClassName}
      footerClassName={footerClassName}
    >
      {children}
    </CollapsiblePanel>
  );
};

CollapsiblePanel.propTypes = {
  // Main
  isOpen: PropTypes.bool,
  renderHeader: PropTypes.func,
  children: PropTypes.node,
  headerText: PropTypes.string,

  // Icons
  leftIcon: PropTypes.string,

  // Custom styles
  containerClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  footerClassName: PropTypes.string,

  // Events
  onHeaderClick: PropTypes.func,
};

CollapsiblePanel.defaultProps = {
  // Main
  isOpen: false,
  renderHeader: null,
  children: null,
  headerText: '',

  // Icons
  leftIcon: '',

  // Custom styles
  containerClassName: '',
  headerClassName: '',
  bodyClassName: '',
  footerClassName: '',

  // Events
  onHeaderClick: () => {},
};

SimpleCollapsiblePanel.propTypes = {
  // Main
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  headerText: PropTypes.string,
  renderHeader: PropTypes.func,

  // Icons
  leftIcon: PropTypes.string,

  // Custom styles
  containerClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  footerClassName: PropTypes.string,

  // Events
  onHeaderClick: PropTypes.func,
};

SimpleCollapsiblePanel.defaultProps = {
  // Main
  isOpen: false,
  children: null,
  headerText: '',
  renderHeader: null,

  // Icons
  leftIcon: '',

  // Custom styles
  containerClassName: '',
  headerClassName: '',
  bodyClassName: '',
  footerClassName: '',

  // Events
  onHeaderClick: () => {},
};

export default CollapsiblePanel;
