import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ LoaderClassname }) => {
  return (
    <div className={`loader-section  ${LoaderClassname || ''}`}>
      <div className="loader">
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

Loader.propTypes = {
  LoaderClassname: PropTypes.string,
};

Loader.defaultProps = {
  LoaderClassname: '',
};

export default Loader;
