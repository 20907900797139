import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const StatusBox = props => {
  const { statusText, allFieldsComplete } = props;
  const outerBoxclasses = classNames(
    'status-box',
    { 'without-errors': allFieldsComplete },
    { 'with-errors': !allFieldsComplete }
  );
  const iconClasses = classNames(
    'fas',
    { 'fa-check-circle': allFieldsComplete },
    { 'fa-exclamation-triangle': !allFieldsComplete }
  );

  return (
    <div className={outerBoxclasses}>
      <p>
        <i className={iconClasses} />
        {statusText}
      </p>
    </div>
  );
};

StatusBox.defaultProps = {
  statusText: '',
  allFieldsComplete: false,
};

StatusBox.propTypes = {
  statusText: PropTypes.string,
  allFieldsComplete: PropTypes.bool,
};

export default StatusBox;
