import React, { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import 'react-notifications-component/dist/theme.css';
import store from './store';
import { Loader } from './common/components';
import PrivateRoute from './common/hoc/private-route';
import './App.scss';

const Home = lazy(() =>
  import(/* webpackChunkName: "home" */ './features/home')
);
const Authentication = lazy(() =>
  import(/* webpackChunkName: "authentication" */ './features/authentication')
);
const AuthLogin = lazy(() =>
  import('./features/authentication/login/auth-login')
);

const MapSearch = lazy(() =>
  import(
    /* webpackChunkName: "map-search" */ './features/geo-location/map-search'
  )
);
const Map = lazy(() =>
  import(/* webpackChunkName: "map-draw" */ './features/geo-location/mapbox')
);
const MapTour = lazy(() =>
  import(/* webpackChunkName: "map-tour" */ './features/geo-location/map-tour')
);

const App = () => {
  const isAzureMFAEnabled = process.env.REACT_APP_IS_AZURE_MFA_ENABLED;

  return (
    <>
      {isAzureMFAEnabled?.toLowerCase() !== 'true' ? (
        <Provider store={store}>
          <Suspense fallback={<Loader />}>
            <Router>
              <ReactNotification />
              <Switch>
                <Route path="/login" component={Authentication} />
                <PrivateRoute path="/map-search" component={MapSearch} />
                <PrivateRoute path="/map-draw" component={Map} />
                <Route path="/map-tour" component={MapTour} />
                <PrivateRoute path="/manage-map-tour" component={MapTour} />
                <PrivateRoute path="/manage-pin-location" component={Map} />
                <PrivateRoute path="/" component={Home} />
                <Redirect from="*" to="/" />
              </Switch>
            </Router>
          </Suspense>
        </Provider>
      ) : (
        <Provider store={store}>
          <Suspense fallback={<Loader />}>
            <UnauthenticatedTemplate>
              <Router>
                <Switch>
                  <Route path="/map-tour" component={MapTour} />
                  <Route path="/">
                    <AuthLogin />
                  </Route>
                  <Redirect from="*" to="/" />
                </Switch>
              </Router>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <Router>
                <ReactNotification />
                <Switch>
                  <Route path="/login" component={Authentication} />
                  <PrivateRoute path="/map-search" component={MapSearch} />
                  <PrivateRoute path="/map-draw" component={Map} />
                  <Route path="/map-tour" component={MapTour} />
                  <PrivateRoute path="/manage-map-tour" component={MapTour} />
                  <PrivateRoute path="/manage-pin-location" component={Map} />
                  <PrivateRoute path="/" component={Home} />
                  <Redirect from="*" to="/" />
                </Switch>
              </Router>
            </AuthenticatedTemplate>
          </Suspense>
        </Provider>
      )}
    </>
  );
};

export default App;
