/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';

const Textarea = ({
  disabled,
  name,
  rows,
  formInput,
  type,
  label,
  placeholder,
  required,
  ...rest
}) => {
  return (
    <div className="text-area">
      {label && (
        <label htmlFor={name}>
          {label} <span>{required ? '*' : ''}</span>
        </label>
      )}
      {formInput ? (
        <>
          <Field
            rows={rows}
            name={name}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
            required={required}
            component="textarea"
            className={classNames('form-control', 'input')}
            {...rest}
          />
          <div className="error-msg">
            <ErrorMessage name={name} />
          </div>
        </>
      ) : (
        <textarea
          rows={rows}
          name={name}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          className={classNames('form-control', 'input')}
          {...rest}
        ></textarea>
      )}
    </div>
  );
};

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  formInput: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
};

Textarea.defaultProps = {
  disabled: false,
  required: false,
  formInput: true,
  type: 'text',
  label: '',
  placeholder: '',
  rows: 6,
};

export default Textarea;
