export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const createNotification = payload => ({
  type: CREATE_NOTIFICATION,
  payload,
});

export const removeNotification = () => ({
  type: REMOVE_NOTIFICATION,
});
