import React, { useState } from 'react';
import { Formik, Form } from 'formik';

import { Modal, Button, Textarea } from '../../../common/components';

const ReservationUpdatePopup = props => {
  const { toggle, isOpen, handleUpdate, loading } = props;
  const [reason, setReason] = useState('');

  const handleSubmit = () => {
    handleUpdate(reason);
    setReason('');
  };

  return (
    <Modal
      className="modal-add-edit-garden delete-document-wrapper"
      title="Enter a Reason*"
      isOpen={isOpen}
      toggle={toggle}
    >
      <Formik>
        <Form className="form-add-edit-garden">
          <div className="container-textarea input-field">
            <Textarea
              name="reason"
              maxLength={500}
              placeholder={`To update property status, please write a comment...`}
              onChange={event => setReason(event.target.value)}
            />
          </div>
          <span className="delete-lenght-note">* Max 500 characters</span>
          <div className="buttons-container-reason">
            <Button
              color="tertiary"
              size="md"
              disabled={!reason.trim() || loading}
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button color="default" size="md" className="ml-4" onClick={toggle}>
              Cancel
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ReservationUpdatePopup;
