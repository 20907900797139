/* eslint-disable react/state-in-constructor */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';

import ConfirmationModal from '../confirmation-modal/confirmation-modal';

class RouteLeavingGuard extends Component {
  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false,
  };

  showModal = location =>
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });

  closeModal = callback => {
    this.setState(
      {
        modalVisible: false,
      },
      callback
    );
  };

  cancelNavigation = () => {
    const { closeModal } = this.props;

    if (closeModal) {
      closeModal();
    }

    this.closeModal(() => {});
  };

  handleBlockedNavigation = nextLocation => {
    const { confirmedNavigation } = this.state;
    const { shouldBlockNavigation } = this.props;
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      this.showModal(nextLocation);
      return false;
    }
    return true;
  };

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { navigate } = this.props;
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState(
          {
            confirmedNavigation: true,
          },
          () => {
            navigate(lastLocation.pathname);
          }
        );
      }
    });

  render() {
    const { when } = this.props;
    const { modalVisible } = this.state;
    return (
      <div>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <ConfirmationModal
          toggleAlertState={modalVisible}
          proceedNavigation={this.handleConfirmNavigationClick}
          cancelNavigation={this.cancelNavigation}
          msg="Do you want to discard the changes?"
        />
      </div>
    );
  }
}

RouteLeavingGuard.propTypes = {
  when: PropTypes.bool,
  navigate: PropTypes.func,
  closeModal: PropTypes.func,
  shouldBlockNavigation: PropTypes.func,
};

RouteLeavingGuard.defaultProps = {
  when: false,
  navigate: () => {},
  closeModal: () => {},
  shouldBlockNavigation: () => {},
};

export default RouteLeavingGuard;
