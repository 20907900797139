import produce from 'immer';
import { LOGIN_SUCCESS, EDIT_USER } from '../actions/auth';
import { isSessionExpired } from '../utils/global';

let token = localStorage.getItem('token');
const isSalesApp = JSON.parse(localStorage.getItem('user'))?.isSalesApp;
token = !isSalesApp && isSessionExpired(token) ? null : token;
const user = token ? JSON.parse(localStorage.getItem('user')) : null;
const permissions = token
  ? JSON.parse(localStorage.getItem('permissions'))
  : [];

const initialState = {
  user,
  isLoggedIn: token != null,
  token,
  permissions,
};

export default (state = initialState, action) =>
  produce(state, draftState => {
    switch (action.type) {
      case LOGIN_SUCCESS:
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('user', JSON.stringify(action.payload));
        localStorage.setItem(
          'permissions',
          JSON.stringify(action.payload.permissions)
        );
        draftState.user = action.payload;
        draftState.isLoggedIn = true;
        draftState.token = action.payload.token;
        return;

      case EDIT_USER:
        localStorage.setItem('user', JSON.stringify(action.payload));
        draftState.user = action.payload;
        return;

      default:
        return;
    }
  });
